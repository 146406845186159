import React from "react";
import {Row, Col, Card } from "react-bootstrap";
import Button from "../buttons/button"

export const Cursos_cards = ({ items, sectionTitle }) => {
    return (
        <Row className="neContainer justify-content-center g-3 g-md-3">
            {sectionTitle !== undefined && (
                <h2>{sectionTitle}</h2>
            )}
            {items.map((entry, key) => {
                return (
                    <Col xs={12} md={6}  className="d-flex" key={key}>
                        <Card className="neCard flex-fill"
                              data-sal="fade"
                              data-sal-delay={100 * key}
                              data-sal-duration="200"
                        >
                            <div
                                className="neBanner"
                                style={{
                                    backgroundImage: `url(${entry.neBanner})`,
                                    backgroundSize: "cover",
                                }}
                            >

                            </div>
                            <Card.Header className="neHeader">
                                <h3 className="card-title neTitle"><div dangerouslySetInnerHTML={ {__html: entry.neTitle} } /></h3>
                            </Card.Header>
                            <div className="card-body neContent">
                                <div className="card-text neText">
                                    <div dangerouslySetInnerHTML={ {__html: entry.neText} } />
                                </div>
                                <div className="ne-holder-button py-3 py-md-5">
                                    <Button variant="secondary neButton" text="Ver más" />
                                </div>

                            </div>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
};

export default Cursos_cards;
